/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import CaixaMensagem from "@/components/chat/conversa/mensagem/CaixaMensagem";
import { EsqueletoMensagens } from "@/components/global/carregamento/EsqueletoMensagens";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { agruparMensagensPorData } from "@/util/ChatUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

interface CorpoConversaProps {
    setMensagemResposta: React.Dispatch<React.SetStateAction<MensagemType | undefined>>;
}

const i18n = ptBR;

const CorpoConversa = ({ setMensagemResposta }: CorpoConversaProps) => {
    const [conversaAnterior, setConversaAnterior] = useState<ConversaType | null>(null);
    const { conversa, carregando, alteraCarregando, adicionarListaMensagens, desconectarConversaAntiga, mensagens } =
        useConversaStore();
    const mensagensContainerRef = useRef<HTMLDivElement>(null);

    const carregaMensagensIniciais = useCallback(
        async (codConversa: string, signal: AbortSignal) => {
            alteraCarregando(true);
            try {
                const url = `${process.env.NEXT_PUBLIC_CHAT_API_REST_GET_MENSAGENS_CONVERSA_URL}?codConversa=${codConversa}`;
                const retorno = await fetch(url, { cache: "no-store", signal });
                if (!retorno.ok) throw new Error(i18n.err_buscar_mensagens);
                const mensagens = await retorno.json();
                adicionarListaMensagens(mensagens);
                alteraCarregando(false);
            } catch (erro: any) {
                if (erro.name !== "AbortError") {
                    toast(erro.message, { toastId: "erro-ordenar-mensagens", type: "error" });
                }
            }
        },
        [alteraCarregando]
    );

    useEffect(() => {
        const abortController = new AbortController();
        if (conversa) {
            alteraCarregando(true);
            setConversaAnterior(conversa);
            carregaMensagensIniciais(conversa.codConversa, abortController.signal);
        }

        return () => {
            desconectarConversaAntiga(conversaAnterior?.codConversa);
            abortController.abort();
        };
    }, [conversa]);

    useEffect(() => {
        if (mensagensContainerRef.current) {
            mensagensContainerRef.current.scrollTop = mensagensContainerRef.current.scrollHeight;
        }
    }, [mensagens, carregando]);

    const renderMensagens = () => {
        if (!conversa || mensagens.length === 0) {
            return (
                <div className="w-full mt-40 mx-auto my-auto text-center ">
                    <span>{i18n.lbl_nenhuma_mensagem_conversa}</span>
                </div>
            );
        }
        const mensagensAgrupadas = agruparMensagensPorData(mensagens);

        return (
            <>
                {Array.from(mensagensAgrupadas).map(([data, mensagensDia]) => (
                    <div key={data}>
                        <div className="flex mb-10 justify-center sticky top-2 z-10">
                            <span
                                className="px-3 py-2 absolute bg-green-700 text-sm 
                                text-neutral-100 rounded-full">
                                {data}
                            </span>
                        </div>
                        {mensagensDia.map((mensagem) => (
                            <CaixaMensagem
                                key={mensagem.codMensagem}
                                mensagem={mensagem}
                                nomeContato={conversa.nomeContato}
                                setMensagemResposta={setMensagemResposta}
                            />
                        ))}
                    </div>
                ))}
            </>
        );
    };

    return (
        <div
            className="flex-1 overflow-y-auto scroll-smooth bg-chat-white"
            ref={mensagensContainerRef}>
            {carregando ? <EsqueletoMensagens /> : renderMensagens()}
        </div>
    );
};

export default CorpoConversa;
