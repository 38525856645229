"use client;";
import { criarNovaConversa } from "@/api/chatAPI";
import { BotaoAzul } from "@/components/global/botoes/Botoes";
import { InputComLabel, InputTelefoneComLabel } from "@/components/global/inputs/Input";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { formatarTelefone } from "@/util/FormatacaoUtils";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const i18n = ptBR;

interface CriarConversaProps {
    contato?: {
        nome: string;
        telefone: string;
    };
}
const CriarConversa = ({ contato }: CriarConversaProps) => {
    const [nome, setNome] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [telefoneFormatado, setTelefoneFormatado] = useState<string>("");
    const { alterarConversaAtiva } = useConversaStore();

    useEffect(() => {
        if (contato) {
            setNome(contato.nome);
            setTelefone(contato.telefone);
            setTelefoneFormatado(formatarTelefone(contato.telefone));
        }
    }, [contato]);
    const handleCriarConversa = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!nome || !telefone) {
            toast(i18n.err_todos_campos_obrigatorios, {
                type: "error",
                toastId: "erro-criar-conversa-campos-obrigatorios",
            });
        }
        try {
            const conversaRetorno = await criarNovaConversa(telefone, nome);
            if (conversaRetorno) {
                alterarConversaAtiva(conversaRetorno);
            }
        } catch {
            toast(i18n.err_criar_conversa, {
                type: "error",
                toastId: "erro-criar-conversa-campos",
            });
        }
    };

    const handleChangeTelefone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valorSemFormatacao = event.target.value.replace(/\D/g, "");
        const valorFormatado = formatarTelefone(valorSemFormatacao);
        setTelefone(valorSemFormatacao);
        setTelefoneFormatado(valorFormatado);
    };

    return (
        <form
            className="flex w-full flex-col"
            onSubmit={handleCriarConversa}>
            <h1 className="w-full text-center font-medium py-2 text-2xl">{i18n.tit_criar_conversa}</h1>
            <span className="flex justify-start items-start mt-2">
                <InputComLabel
                    label={i18n.lbl_nome_contato}
                    id="nomeContato"
                    valor={nome}
                    onChange={(e) => setNome(e.target.value)}
                />
            </span>
            <span className="flex justify-start items-start mt-2">
                <InputTelefoneComLabel
                    label={i18n.lbl_tel_contato}
                    id="telContato"
                    onChange={handleChangeTelefone}
                    valor={telefoneFormatado}
                />
            </span>
            <BotaoAzul
                label={i18n.btn_criar_conversa}
                className="mt-4 text-white"
                tipo="submit"
            />
        </form>
    );
};

export default CriarConversa;
