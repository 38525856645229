import { salvarArquivoEnvio } from "@/api/chatAPI";
import { ptBR } from "@/i18n/pt-br";
import { ArquivoRespostaType } from "@/types/ArquivoTypes";
import {
    TipoMensagemEnum,
    converteArquivoParaBase64,
    converterBlobParaBase64,
    determinarTipoDeArquivo,
    gerarStringAleatoria,
} from "@/util/ChatUtils";
import { toast } from "react-toastify";

const i18n = ptBR;

export const processarAudio = async (audioBlob: Blob): Promise<ArquivoRespostaType | null> => {
    try {
        const audioBase64 = await converterBlobParaBase64(audioBlob);
        return await salvarArquivoEnvio({
            nome: gerarStringAleatoria(14),
            extensao: "webm",
            arquivo: audioBase64,
            tipoMensagem: TipoMensagemEnum.AUDIO,
        });
    } catch (erro) {
        tratarErro(erro, "erro-salvar-audio");
        return null;
    }
};

export const processarArquivo = async (arquivo: File): Promise<ArquivoRespostaType | null> => {
    try {
        const arquivoBase64 = await converteArquivoParaBase64(arquivo);
        return await salvarArquivoEnvio({
            nome: obterNomeSemExtensao(arquivo),
            extensao: obterExtensao(arquivo),
            arquivo: arquivoBase64,
            tipoMensagem: determinarTipoDeArquivo(arquivo),
        });
    } catch (erro) {
        tratarErro(erro, "erro-salvar-arquivo");
        return null;
    }
};

const obterNomeSemExtensao = (arquivo: File): string =>
    arquivo.name.substring(0, arquivo.name.lastIndexOf(".")) || arquivo.name;

const obterExtensao = (arquivo: File): string => arquivo.name.substring(arquivo.name.lastIndexOf(".") + 1);

export const tratarErro = (erro: any, toastId: string) => {
    toast(erro.message || i18n.err_interno, {
        toastId: toastId,
        type: "error",
    });
};
