"use client";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { ConversaType } from "@/types/ConversaTypes";
import { StatusAtendimentoType } from "@/types/GeralTypes";
import { MenuLateralEnum } from "@/types/MenuTypes";
import { useEffect } from "react";
import ListagemConversa from "./conversas/ListagemConversas";
import MenuLateral from "./menu/MenuLateral";

const Sidebar = () => {
    const { adicionaOuAlterarConversa, removerConversa, menuLateral } = useListagemLateralStore();
    const { usuario } = useUsuarioStore();

    const getStatusAtendimentoListagem = (): StatusAtendimentoType => {
        return menuLateral === MenuLateralEnum.SOLUCIONADO
            ? StatusAtendimentoType.SOLUCIONADO
            : StatusAtendimentoType.ABERTO;
    };

    const atualizaListaConversas = (novaConversa: ConversaType) => {
        if (novaConversa.statusAtendimento === getStatusAtendimentoListagem()) {
            adicionaOuAlterarConversa(novaConversa);
        } else {
            removerConversa(novaConversa);
        }
    };

    useEffect(() => {
        const novoEventSource = new EventSource(`${process.env.NEXT_PUBLIC_CHAT_INSCREVER_SSE_CONVERSA_URL}`);
        novoEventSource.onmessage = (event) => {
            const novaConversa: ConversaType = JSON.parse(event.data);
            if (
                menuLateral === MenuLateralEnum.MINHAS_CONVERSAS &&
                (!novaConversa.usuarioResponsavel?.login || novaConversa.usuarioResponsavel.login !== usuario?.login)
            ) {
                removerConversa(novaConversa);
                return;
            }

            if (
                menuLateral === MenuLateralEnum.MEUS_SETORES &&
                (!novaConversa.setor ||
                    !usuario?.setores.some(
                        (setor) => setor.descricao.toLowerCase() === novaConversa.setor?.toLowerCase()
                    ))
            ) {
                removerConversa(novaConversa);
                return;
            }
            atualizaListaConversas(novaConversa);
        };

        return () => novoEventSource.close();
    }, [menuLateral]);

    return (
        <div className="h-full w-[500px]">
            <div className="h-full flex">
                <MenuLateral />
                <section className="h-full w-96 min-w-[150px]">
                    <ListagemConversa />
                </section>
            </div>
        </div>
    );
};

export default Sidebar;
