"use client";
import { useAudioStore } from "@/store/audioStore";
import { faMicrophone, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";

const BotaoGravacaoAudio: React.FC = () => {
    const [gravando, setGravando] = useState(false);
    const gravadorMidiaRef = useRef<MediaRecorder | null>(null);
    const partesAudioRef = useRef<Blob[]>([]);
    const { adicionarAudioEnvio } = useAudioStore();

    const iniciarGravacao = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            gravadorMidiaRef.current = new MediaRecorder(stream);

            gravadorMidiaRef.current.ondataavailable = (evento) => {
                if (evento.data.size > 0) {
                    partesAudioRef.current.push(evento.data);
                }
            };

            gravadorMidiaRef.current.onstop = () => {
                const blobAudio = new Blob(partesAudioRef.current, { type: "audio/webm" });
                adicionarAudioEnvio(blobAudio);
            };

            gravadorMidiaRef.current.start();
            setGravando(true);
        } catch (erro) {
            console.error("Erro ao iniciar a gravação:", erro);
        }
    };

    const pararGravacao = () => {
        if (gravadorMidiaRef.current) {
            gravadorMidiaRef.current.stop();
            partesAudioRef.current = [];
            setGravando(false); // Limpa os chunks para a próxima gravação
        }
    };

    return (
        <button
            type="button"
            onClick={gravando ? pararGravacao : iniciarGravacao}
            className={`py-2 px-3 rounded-full ${
                gravando ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"
            } transition-colors duration-300`}>
            <FontAwesomeIcon
                icon={gravando ? faStop : faMicrophone}
                className="text-white"
                size="lg"
            />
        </button>
    );
};

export default BotaoGravacaoAudio;

export const ReprodutorAudio = ({
    src,
    className,
    codMensagem,
}: {
    src: string;
    className?: string;
    codMensagem: string;
}) => {
    const { codMensagemReproduzindo, alterarAudioReproduzindo } = useAudioStore();
    const audioRef = useRef<HTMLAudioElement>(null);

    const pararAudio = useCallback(() => {
        audioRef?.current?.pause();
    }, []);

    useEffect(() => {
        if (codMensagemReproduzindo !== codMensagem) {
            pararAudio();
        }
    }, [codMensagemReproduzindo, codMensagem, pararAudio]);

    return (
        <audio
            src={src}
            ref={audioRef}
            onPlay={() => {
                alterarAudioReproduzindo(codMensagem);
            }}
            className={`${className} w-full min-w-64 mt-1 h-10 bg-gradient-to-r from-green-900 to-green-500 rounded-full p-1 
                text-white focus:outline-none`}
            controls
        />
    );
};
