/* eslint-disable @next/next/no-img-element */
"use client";
import { ReprodutorAudio } from "@/components/global/botoes/BotoesAudio";
import CriarConversa from "@/components/global/modal/conteudo/CriarConversa";
import { ptBR } from "@/i18n/pt-br";
import { useModalStore } from "@/store/modalStore";
import { MensagemType } from "@/types/MensagemTypes";
import { TipoMensagemEnum } from "@/util/ChatUtils";
import { formatarTelefone } from "@/util/FormatacaoUtils";
import { useState } from "react";
import FragmentoResposta from "./FragmentoResposta";

const i18n = ptBR;

const ConteudoMensagem = (mensagem: MensagemType, isRemetente: boolean) => {
    const { abrirModal } = useModalStore();
    const [isMaximizado, setIsMaximizado] = useState(false);
    const handleClickArquivo = () => {
        setIsMaximizado(!isMaximizado);
    };

    const retornaConteudoPorTipo = () => {
        let contato: any;
        switch (mensagem.tipo) {
            case TipoMensagemEnum.IMAGE:
                return (
                    <div
                        className="max-w-[300px] h-[450px] cursor-pointer"
                        onClick={handleClickArquivo}>
                        <img
                            src={mensagem.arquivo}
                            alt={mensagem.texto ?? i18n.lbl_mensagem_imagem}
                            className={`${isMaximizado ? "fixed top-0 left-0 w-screen h-screen object-contain z-50" : "h-fit"}`}
                        />
                    </div>
                );
            case TipoMensagemEnum.DOCUMENT:
                return (
                    <embed
                        src={mensagem.arquivo}
                        title={mensagem.texto ?? i18n.lbl_mensagem_documento}
                        width="100%"
                        height="500px"
                    />
                );
            case TipoMensagemEnum.VIDEO:
                return (
                    <video
                        controls
                        className="max-h-96">
                        <source src={mensagem.arquivo} />
                    </video>
                );
            case TipoMensagemEnum.AUDIO:
                return (
                    <ReprodutorAudio
                        codMensagem={mensagem.codMensagem}
                        src={mensagem.arquivo}
                    />
                );
            case TipoMensagemEnum.LOCATION:
                return (
                    <>
                        <iframe
                            className="w-full h-48"
                            src={`https://google.com/maps/embed/v1/place?key=${process.env.NEXT_PUBLIC_CHAVE_GOOGLE_MAPS}&q=${mensagem.localizacao?.latitude},${mensagem.localizacao?.longitude}&center=${mensagem.localizacao?.latitude},${mensagem.localizacao?.longitude}`}
                            loading="lazy"
                            title={i18n.lbl_localizacao}
                        />
                        {mensagem.localizacao?.endereco && (
                            <p>{`${i18n.lbl_endereco}: ${mensagem.localizacao?.endereco}`}</p>
                        )}
                    </>
                );
            case TipoMensagemEnum.CONTACTS:
                if (!mensagem.contato) return;
                contato = {
                    nome: mensagem.contato.nomeContato,
                    telefone: mensagem.contato.telContato,
                };
                return (
                    <div
                        className="flex flex-col items-center w-full bg-slate-100 border py-2 px-3 
                        rounded-md hover:cursor-pointer mb-1"
                        onClick={() => abrirModal(<CriarConversa contato={contato} />)}>
                        <span className="w-full bg-green-300 rounded-md text-center font-semibold">
                            {i18n.lbl_contato}
                        </span>
                        <span>{`${mensagem.contato?.nomeContato}`}</span>
                        <span>{`${formatarTelefone(mensagem.contato?.telContato)}`}</span>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {mensagem.mensagemResposta && (
                <div
                    className={`p-2 flex rounded-md justify-between items-center
                                    ${isRemetente ? "bg-green-50 mt-2" : "bg-gray-300"} border-l-green-700 border-l-8`}>
                    <FragmentoResposta mensagemResposta={mensagem.mensagemResposta} />
                </div>
            )}
            {retornaConteudoPorTipo()}
            {mensagem.texto && (
                <p className="px-1 pt-1 break-words whitespace-pre-line hyphens-auto">{mensagem.texto}</p>
            )}
        </>
    );
};

export default ConteudoMensagem;
