import { ConversaType } from "@/types/ConversaTypes";
import { MenuLateralEnum, MenuLateralType } from "@/types/MenuTypes";
import { ordenarConversasPorData } from "@/util/ChatUtils";
import { create } from "zustand";

type ListagemLateralStore = {
    menuLateral: MenuLateralType;
    conversas: ConversaType[];
    conversasFiltradas: ConversaType[];
    apenasNaoAssociadas: boolean;
    alterarMenuLateral: (menu: MenuLateralType) => void;
    adicionarListaConversas: (listaConversas: ConversaType[]) => void;
    removerConversa: (conversaRemovida: ConversaType) => void;
    adicionaOuAlterarConversa: (conversaAlterada: ConversaType) => void;
    pesquisarConversas: (texto: string) => void;
    alterarStatusApenasNaoAssociadas: (statusApenasNaoAssociadas: boolean) => void;
    filtrarConversasNaoAssociadas: (apenasNaoAssociadas: boolean) => void;
};

export const useListagemLateralStore = create<ListagemLateralStore>((set, get) => ({
    conversas: [],
    conversasFiltradas: [],
    menuLateral: MenuLateralEnum.CONVERSAS_ABERTAS,
    apenasNaoAssociadas: false,
    alterarMenuLateral: (menu: MenuLateralType) => {
        set((state) => ({
            menuLateral: menu,
        }));
    },

    adicionarListaConversas: (listaConversas: ConversaType[]) => {
        set((state) => ({
            conversas: ordenarConversasPorData(listaConversas),
            conversasFiltradas: state.conversas,
        }));
    },
    adicionaOuAlterarConversa: (conversaAlterada: ConversaType) => {
        const conversas = get().conversas;
        const index = conversas.findIndex((item) => item.codConversa === conversaAlterada.codConversa);
        let conversasOrdenadas;
        if (index > -1) {
            conversasOrdenadas = ordenarConversasPorData([
                ...conversas.slice(0, index),
                conversaAlterada,
                ...conversas.slice(index + 1),
            ]);
        } else {
            conversasOrdenadas = ordenarConversasPorData([...conversas, conversaAlterada]);
        }

        set((state) => ({
            conversas: conversasOrdenadas,
        }));
    },
    removerConversa: (conversaRemovida: ConversaType) => {
        set((state) => ({
            conversas: state.conversas.filter((conversa) => conversa.codConversa !== conversaRemovida.codConversa),
            conversasFiltradas: state.conversasFiltradas.filter(
                (conversa) => conversa.codConversa !== conversaRemovida.codConversa
            ),
        }));
    },
    pesquisarConversas: (texto: string) => {
        set((state) => ({
            conversasFiltradas: state.conversas.filter((conversa) =>
                Object.values(conversa).some(
                    (value) => typeof value === "string" && value.toLowerCase().includes(texto.toLowerCase())
                )
            ),
        }));
    },
    alterarStatusApenasNaoAssociadas: (statusApenasNaoAssociadas: boolean) => {
        set((state) => ({
            apenasNaoAssociadas: statusApenasNaoAssociadas,
        }));
    },
    filtrarConversasNaoAssociadas: (apenasNaoAssociadas: boolean) => {
        const conversas = get().conversas;
        let conversasFiltro;

        if (apenasNaoAssociadas) {
            conversasFiltro = conversas.filter((conversa) => {
                if (!conversa.setor && !conversa.usuarioResponsavel) return conversa;
            });
        } else {
            conversasFiltro = conversas;
        }

        set((state) => ({
            conversasFiltradas: conversasFiltro,
        }));
    },
}));
