import {
    alterarStatusConversa,
    alterarUsuarioResponsavel,
    marcarConversaComoNaoLida,
    visualizarMensagensConversa,
} from "@/api/chatAPI";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { ConversaType } from "@/types/ConversaTypes";

export const arquivarConversa = async (conversa: ConversaType) => {
    useListagemLateralStore.getState().removerConversa(conversa);
    const usuarioAlteracao = useUsuarioStore.getState().usuario?.nomeCompleto;
    if (!usuarioAlteracao) return;
    alterarStatusConversa(conversa.codConversa, usuarioAlteracao);
};

export const marcarComoNaoLida = async (conversa: ConversaType) => {
    marcarConversaComoNaoLida(conversa.codConversa);
};

export const marcarComoLida = async (conversa: ConversaType) => {
    visualizarMensagensConversa(conversa.codConversa);
};

export const alterarResponsavelConversa = async (login: string, codConversa: string | null, desassociar: boolean) => {
    const usuarioAlteracao = useUsuarioStore.getState().usuario?.nomeCompleto;
    if (!usuarioAlteracao) return;
    alterarUsuarioResponsavel(login, codConversa, desassociar, usuarioAlteracao);
};
