"use client";
import { iniciarConversaInativa } from "@/api/chatAPI";
import { BotaoExcluir, BotaoSubmitMensagem } from "@/components/global/botoes/Botoes";
import BotaoGravacaoAudio, { ReprodutorAudio } from "@/components/global/botoes/BotoesAudio";
import { ptBR } from "@/i18n/pt-br";
import { useAudioStore } from "@/store/audioStore";
import { useConversaStore } from "@/store/conversaStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { ArquivoRespostaType } from "@/types/ArquivoTypes";
import { MensagemEnvioType, MensagemType } from "@/types/MensagemTypes";
import { CookieEnum, TipoMensagemEnum, determinarTipoDeArquivo, isNullOrEmpty } from "@/util/ChatUtils";
import { retornaTemplateIniciarConversaInativa } from "@/util/TemplateUtils";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "cookies-next";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import FragmentoResposta from "./mensagem/FragmentoResposta";
import { processarArquivo, processarAudio, tratarErro } from "./mensagem/FuncoesMensagem";

const i18n = ptBR;

interface FormMensagemProps {
    mensagemResposta?: MensagemType;
    setMensagemResposta: React.Dispatch<React.SetStateAction<MensagemType | undefined>>;
}

const FormMensagem = ({ mensagemResposta, setMensagemResposta }: FormMensagemProps) => {
    const [textoMensagem, setTextoMensagem] = useState<string>("");
    const [arquivoNovaMensagem, setArquivoNovaMensagem] = useState<any>();
    const { conversa, stompClient, verificarSeConversaInativa } = useConversaStore();
    const { audioEnvio, removerAudioEnvio } = useAudioStore();
    const { usuario } = useUsuarioStore();
    const inputUpload = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const router = useRouter();
    const conversaInativa = verificarSeConversaInativa();
    const msgInicarConversaInativa = retornaTemplateIniciarConversaInativa(
        conversa?.nomeContato,
        usuario?.nomeCompleto
    );

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [textoMensagem]);

    const enviarMensagem = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const usuarioResponsavel = getCookie(CookieEnum.LOGIN_USUARIO);

        if (process.env.NEXT_PUBLIC_AMBIENTE === "prod" && !usuarioResponsavel) {
            router.push("/login");
            return;
        }

        if (!conversa || !stompClient) return;

        if (conversaInativa) {
            if (!usuario) return;
            const primeiroNomeContato = conversa.nomeContato.split(" ")[0];
            const primeroNomeUsuario = usuario.nomeCompleto.split(" ")[0];
            const arrayParametros = [primeiroNomeContato, primeroNomeUsuario];

            iniciarConversaInativa(conversa.telContato, arrayParametros);
            return;
        }

        const arquivos = inputUpload.current?.files;
        const isMensagemVazia = isNullOrEmpty(textoMensagem);
        const isArquivoVazio = arquivos?.length === 0;

        if (isMensagemVazia && isArquivoVazio && !audioEnvio) {
            return;
        }

        let tipoMensagem = TipoMensagemEnum.TEXT;
        let arquivoSalvo: ArquivoRespostaType | null = null;

        if (arquivos && arquivos.length > 0) {
            arquivoSalvo = await processarArquivo(arquivos[0]);
            if (!arquivoSalvo) return;
            tipoMensagem = determinarTipoDeArquivo(arquivos[0]);
        } else if (audioEnvio) {
            arquivoSalvo = await processarAudio(audioEnvio);
            if (!arquivoSalvo) return;
            tipoMensagem = TipoMensagemEnum.AUDIO;
            removerAudioEnvio();
        }

        const mensagemEnvio = criarMensagemEnvio(usuarioResponsavel, tipoMensagem, arquivoSalvo);
        if (!mensagemEnvio) return;
        if (mensagemResposta) {
            mensagemEnvio.codMensagemResposta = mensagemResposta.codMensagem;
        }

        try {
            stompClient.publish({
                destination: `/chat/${conversa.codConversa}/mensagem`,
                body: JSON.stringify(mensagemEnvio),
                skipContentLengthHeader: true,
            });
        } catch (erro) {
            tratarErro(erro, "erro-enviar-mensagem");
        }

        limparFormulario();
    };

    const criarMensagemEnvio = (
        usuarioResponsavel: string | undefined,
        tipoMensagem: TipoMensagemEnum,
        arquivoSalvo: ArquivoRespostaType | null
    ): MensagemEnvioType | null => {
        if (!conversa) return null;

        return {
            codConversa: conversa.codConversa,
            remetente: usuarioResponsavel?.toString() ?? "sistema",
            tipo: tipoMensagem,
            telContato: conversa.telContato,
            nomeContato: conversa.nomeContato,
            texto: textoMensagem,
            ...(arquivoSalvo && { arquivo: arquivoSalvo }),
        };
    };
    const limparFormulario = () => {
        removerArquivo();
        cancelarRespostaMensagem();
        setTextoMensagem("");
    };

    const handleUploadArquivo = async (event: any) => {
        event.preventDefault();
        setArquivoNovaMensagem(event.target.files[0]);
    };
    const handleClickUpload = (event: any) => {
        inputUpload.current?.click();
    };

    const removerArquivo = () => {
        if (inputUpload.current) {
            inputUpload.current.value = "";
        }
        setArquivoNovaMensagem(undefined);
    };

    const cancelarRespostaMensagem = () => {
        setMensagemResposta(undefined);
    };

    const handleEnter = (event: any) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            formRef.current?.requestSubmit();
        }
    };

    return (
        <>
            {arquivoNovaMensagem && (
                <div className="w-full py-2 px-4 flex justify-between items-center bg-white">
                    <span>{`${i18n.lbl_arquivo_selecionado}: ${arquivoNovaMensagem?.name}`}</span>
                    <BotaoExcluir onClick={removerArquivo} />
                </div>
            )}

            {mensagemResposta && (
                <div className="w-full py-2 px-4 flex justify-between items-center bg-white border-l-green-700 border-l-8">
                    <FragmentoResposta mensagemResposta={mensagemResposta} />
                    <BotaoExcluir onClick={cancelarRespostaMensagem} />
                </div>
            )}

            {audioEnvio && (
                <div className="w-full py-2 px-4 flex justify-between items-center bg-white">
                    <ReprodutorAudio
                        codMensagem={"audio-envio"}
                        src={URL.createObjectURL(audioEnvio)}
                        className="w-10/12"
                    />
                    <BotaoExcluir onClick={removerAudioEnvio} />
                </div>
            )}

            <form
                onSubmit={enviarMensagem}
                ref={formRef}>
                <div className="p-4 bg-white border-t flex items-center w-full">
                    <div className="w-full flex gap-2 items-center justify-between">
                        <BotaoGravacaoAudio />
                        <input
                            className="hidden"
                            ref={inputUpload}
                            onChange={handleUploadArquivo}
                            type="file"
                        />
                        <button
                            className="rounded-full hover:cursor-pointer"
                            type="button"
                            onClick={handleClickUpload}>
                            <FontAwesomeIcon
                                icon={faPaperclip}
                                color="#226020"
                                size="xl"
                            />
                        </button>
                        <textarea
                            ref={textareaRef}
                            autoComplete="off"
                            placeholder={i18n.plc_escreva_mensagem}
                            onChange={(e) => setTextoMensagem(e.target.value)}
                            value={conversaInativa ? msgInicarConversaInativa : textoMensagem}
                            className={`text-black pt-2 px-2 bg-neutral-100 w-11/12 rounded-2xl overflow-y-hidden border resize-none focus:outline-none
                                focus:bg-neutral-50 max-h-[8rem] `}
                            onKeyDown={handleEnter}
                            disabled={conversaInativa}
                        />

                        <BotaoSubmitMensagem />
                    </div>
                </div>
            </form>
        </>
    );
};

export default FormMensagem;
