import { visualizarMensagensConversa } from "@/api/chatAPI";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { ordenarMensagensPorData } from "@/util/ChatUtils";
import { CompatClient, Stomp } from "@stomp/stompjs";
import { differenceInHours, parse } from "date-fns";
import SockJS from "sockjs-client";
import { create } from "zustand";

interface ConversaState {
    conversa: ConversaType | null;
    stompClient: CompatClient | null;
    carregando: boolean;
    mensagens: MensagemType[];
}

interface ConversaActions {
    alteraCarregando: (carregando: boolean) => void;
    adicionarMensagem: (novaMensagem: MensagemType) => void;
    alterarConversaAtiva: (novaConversa: ConversaType) => void;
    adicionarListaMensagens: (listaMensagens: MensagemType[]) => void;
    desconectarWS: () => void;
    conectarWS: () => void;
    desconectarConversaAntiga: (codConversaAntiga: string | null | undefined) => void;
    verificarSeConversaInativa: () => boolean;
}

export const useConversaStore = create<ConversaState & ConversaActions>((set, get) => ({
    conversa: null,
    mensagens: [],
    stompClient: null,
    carregando: false,
    alteraCarregando: (carregando: boolean) => {
        set((state) => ({
            carregando: carregando,
        }));
    },
    adicionarListaMensagens: (listaMensagens: MensagemType[]) => {
        set((state) => ({
            mensagens: ordenarMensagensPorData([...state.mensagens, ...listaMensagens]),
        }));
    },
    adicionarMensagem: (novaMensagem: MensagemType) => {
        const mensagensAtuais = get().mensagens;
        const index = mensagensAtuais.findIndex((mensagem) => mensagem.codMensagem === novaMensagem.codMensagem);
        const listaMensagens =
            index > -1
                ? [...mensagensAtuais.slice(0, index), novaMensagem, ...mensagensAtuais.slice(index + 1)]
                : [...mensagensAtuais, novaMensagem];
        return set({ mensagens: listaMensagens });
    },
    alterarConversaAtiva: (novaConversa: ConversaType) => {
        set((state) => ({
            conversa: novaConversa,
        }));
    },
    conectarWS: () => {
        const conversa = get().conversa;
        const socketFactory = () => {
            return new SockJS(`${process.env.NEXT_PUBLIC_CHAT_API_WEBSOCKET_URL}`);
        };
        const stompClient = Stomp.over(socketFactory);
        const tratarMensagemRecebida = (mensagemWS: any) => {
            const novaMensagem: MensagemType = JSON.parse(mensagemWS.body);
            if (novaMensagem.codConversa !== conversa?.codConversa) return;
            const addMensagem = get().adicionarMensagem;
            addMensagem(novaMensagem);
        };
        const handleConnect = () => {
            if (conversa) {
                stompClient?.subscribe(`/conversa/${conversa.codConversa}/nova-mensagem`, tratarMensagemRecebida);
                visualizarMensagensConversa(conversa.codConversa);
            }
        };
        stompClient.connect({}, handleConnect);
        set((state) => ({
            stompClient: stompClient,
        }));
    },
    desconectarWS: () => {
        get().stompClient?.disconnect();
        set((state) => ({
            stompClient: null,
        }));
    },
    desconectarConversaAntiga: (codConversaAntiga: string | null | undefined) => {
        const stompClient = get().stompClient;
        if (codConversaAntiga && stompClient?.connected) {
            get().stompClient?.unsubscribe(`/conversa/${codConversaAntiga}/nova-mensagem`);
        }
        set((state) => ({
            mensagens: [],
        }));
    },
    verificarSeConversaInativa: () => {
        const formatoDataHora = "dd/MM/yyyy HH:mm:ss";
        const conversa = get().conversa;
        const dataHoraAtual = new Date();

        if (!conversa?.ultimaMensagem?.dataHoraEnvio) return true;

        const dataHoraUltimaMensagem = parse(conversa?.ultimaMensagem?.dataHoraEnvio, formatoDataHora, new Date());
        const diferencaEmHoras = Math.abs(differenceInHours(dataHoraAtual, dataHoraUltimaMensagem));
        return diferencaEmHoras > 24;
    },
}));
