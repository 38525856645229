import { ptBR } from "@/i18n/pt-br";

const message = ptBR;

interface InputBuscaPadraoPros {
    placeholder?: string;
    className?: string;
    onChange: (e: any) => void;
}
export const InputBuscaPadrao: React.FC<InputBuscaPadraoPros> = ({ placeholder, onChange, className }) => {
    return (
        <input
            type="search"
            className={`w-full text-sm pl-2 rounded-md outline-slate-200 ${className} `}
            placeholder={placeholder ?? message.plc_pesquisar}
            onChange={onChange}
        />
    );
};

interface InputComLabelProps {
    placeholder?: string;
    estiloLabel?: string;
    estiloInput?: string;
    label: string;
    valor?: string;
    onChange: (e: any) => void;
    id: string;
    tipo?: "email" | "text" | "password" | "number" | "tel" | "url";
}

export const InputComLabel: React.FC<InputComLabelProps> = ({
    placeholder,
    onChange,
    label,
    estiloInput,
    estiloLabel,
    id,
    tipo,
    valor,
}) => {
    return (
        <>
            <label
                htmlFor={id}
                className={`w-1/3 px-2 ${estiloLabel}`}>{`${label}:`}</label>
            <input
                id={id}
                className={`w-2/3 text-sm px-3 rounded-full border border-slate-400 focus:outline-none ${estiloInput}`}
                type={tipo ?? "text"}
                value={valor}
                placeholder={placeholder}
                onChange={onChange}
            />
        </>
    );
};

interface InputTelefoneComLabelProps {
    placeholder?: string;
    estiloLabel?: string;
    estiloInput?: string;
    label: string;
    onChange: (e: any) => void;
    id: string;
    valor: string;
}

export const InputTelefoneComLabel: React.FC<InputTelefoneComLabelProps> = ({
    placeholder,
    onChange,
    label,
    estiloInput,
    estiloLabel,
    id,
    valor,
}) => {
    return (
        <>
            <label
                htmlFor={id}
                className={`w-1/3 px-2 ${estiloLabel}`}>{`${label}:`}</label>
            <input
                id={id}
                className={`w-2/3 text-sm px-3 rounded-full border border-slate-400 focus:outline-none ${estiloInput}`}
                type="tel"
                value={valor}
                placeholder={placeholder}
                onChange={onChange}
            />
        </>
    );
};
